@tailwind base;
@tailwind components;
@tailwind utilities;
.btn-remove-capital{
  text-transform: none!important;
  font-weight: bold!important;
  min-width: 100px!important;
  /* height: 30px!important; */
}
.btn-rounded{
  border-radius: 20px!important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2)!important;
}

@page { size:8.5in 11in; margin-bottom: 300px;margin: 0; }